@google-theme-color: #4285f4;
@yahoo-theme-color: #400190;
@bing-theme-color: #0c8484;

.search-banner {
  position: relative;
  width: 1024px;
  height: 106px;
  z-index: 4;
  -moz-box-shadow: rgba(0,0,0,.1)0 3px 5px;
  -webkit-box-shadow: rgba(0,0,0,.1)0 3px 5px;
  box-shadow: rgba(0,0,0,.1)0 3px 5px;
}
.search-bg-left{
  float: left;
  a{
    display: block;
    height: 106px;
  }
}
.search-bg-right{
  float: right;
  a{
    display: block;
    height: 106px;
  }
}
.search-form {
  position: absolute;
  left: 268px;
  top: 34px;
  background-color: #fff;
}
.search-selector {
  width: 142px;
  height: 60px;
  position: absolute;
  top: 22px;
  left: 118px;
  cursor: pointer;
  &.show-list {
    border: 1px solid #cfcfcf;
    top: 21px;
    left: 117px;
    background-color: #fff;
  }
}
.search-logo-wrapper {
  .search-arrow-icon {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 26px;
    right: 6px;
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transition: transform .3s;
    &.reversed {
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transition: transform .3s;
    }
  }
}

.search-logo {
  float: left;
  width: 142px;
  height: 60px;
  background-repeat: no-repeat;
}

.search-list {
  display: none;
  position: absolute;
  left: -1px;
  top: 60px;
  background-color: #fff;
  z-index: 20;
  border: 1px solid #cfcfcf;
  border-top: none;

  li {
    display: table;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
  }
}


.search-input {
  width: 496px;
  height: 36px;
  font-size: 16px;
  padding-left: 9px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  position: relative;
}

.search-banner .input-hover {
  border-color: #b3b3b3;
}


.search-btn {
  position: absolute;
  top: 0;
  left: 506px;
  width: 96px;
  height: 38px;
  outline: 0;
  font-size: 18px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  color: #fff;
}

.google-search-banner {
  .input-focus {
    border-color: @google-theme-color;
  }
  .search-btn {
    border: 1px solid @google-theme-color;
    background-color: @google-theme-color;
  }
  .search-btn:hover{
    background-color: #3b78e7;
  }
}

.yahoo-search-banner {
  .input-focus {
    border-color: @yahoo-theme-color;
  }
  .search-btn {
    border: 1px solid @yahoo-theme-color;
    background-color: @yahoo-theme-color;
  }
  .search-btn:hover{
    background-color: #2c0065;
  }
}

.bing-search-banner {
  .input-focus {
    border-color: @bing-theme-color;
  }
  .search-btn {
    border: 1px solid @bing-theme-color;
    background-color: @bing-theme-color;
  }
  .search-btn:hover{
    background-color: darken(@bing-theme-color, 10%);
  }
}

.search-logo{
  &.search-logo-google {
    background-image: url(//gtd.alicdn.com/tfscom/TB1WNczOVXXXXbJaXXXwu0bFXXX);
  }
  &.search-logo-yahoo {
    background-image: url(//gtd.alicdn.com/tfscom/TB1p9ckOVXXXXXKaFXXwu0bFXXX);
  }
  &.search-logo-bing {
    background-image: url(//img.alicdn.com/tfs/TB1wzrZbY_I8KJjy1XaXXbsxpXa-142-60.png);
  }
}




#J_searchSug{
  position: absolute;
  top: 70px;
  left: 268px;
  min-height: 24px;
  z-index: 20;
  width: 504px;
  border: 1px solid #ccc;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);
  background-color: #fff;
}

.search-sug li{
  padding-left: 9px;
  height: 24px;
  line-height: 24px;
  color: #222;
  font-size: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.google-search-banner .sug-hover {
  background-color: #eee;
}

