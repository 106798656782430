#J_hotSites{
  width: 780px;
  height: 240px;
  float: left;
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.hotsite {
  display: block;
  margin: 0 auto;
  position: relative;
}
.hotsite-slider {
  width: 200%;
}

.hotsite-panel {
  position: relative;
  width: 50%;
  float: left;
}
.hs-default-panel{
  position: relative;
  ul {
    display: block;
    height: 219px;
  }
  li{
    float: left;
    width: 128px;
    height: 73px;
    margin: 0 1px;
  }
  .hotsite {
    width: 84px;
    height: 59px;
    margin-top: 6px;
    padding-top: 5px;
    text-align: center;
    &:hover {
      background: #eaeaea;
      border-radius: 5px;
      color: inherit;
    }
    span {
      display: block;
    }
    .hs-img {
      width: 34px;
      height: 34px;
    }
    .hs-corner-img {
      position: absolute;
      top: -2px;
      left: 8px;
      width: 30px;
      height: 30px;
      z-index: 11;
    }
  }

}

.hotsite-switch {
  height: 20px;
  width: 76px;
  margin: 0 auto;
  div {
    width: 50%;
    height: 100%;
    float: left;
    cursor: pointer;
    &.inactive {
      span {
        background: #b2d6ff;
      }
    }

    &:hover span{
      margin-top: 4px;
      height: 8px;
      animation: hoverAnimation .2s ease-in-out;
    }

    span {
      display: block;
      height: 4px;
      width: 30px;
      margin: 0 auto;
      margin-top: 6px;
      border-radius: 2px;
      background: #3e99ff;
    }
  }

}

.hs-custom-panel{
  ul {
    display:block;
    height: 201px;
    padding-top: 18px;
  }
  li {
    float: left;
    width: 128px;
    height: 46px;
    margin: 0 1px;
  }
  .hotsite {
    width: 100px;
    height: 32px;
    line-height: 32px;
    margin-top: 7px;
    animation: none;
    span {
      margin: 0 10px;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .hs-delete {
    display: none;
  }
  &.edit-mode {
    .hotsite {
      background: #eaeaea;
      border-radius: 2px;
      &:hover {
        color: #6b6b6b;
      }
      animation: shaking 0.1s infinite alternate;
    }
    .hs-delete {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      right: -9px;

    }
    .hs-drag-holder {
      display: block;
      width: 98px;
      height: 30px;
      margin: 0 auto;
      margin-top: 7px;
      border: 1px dashed #9b9b9b;
      background-color: #eaeaea;
    }
    .hs-edit-btn, .tips-img {
      display: none;
    }
  }
}
.hs-edit-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 23px;
  height: 23px;
  cursor: pointer;
  background: blue;
  z-index:2;
}

.tips-img {
  position: absolute;
  top: 0;
  z-index: -1;
  display: none;
}

.hs-edit-panel {
  /* display: none; */
  position: absolute;
  width: 100%;
  height: 150px;
  background-color: #8088f7;
  top: 240px;
  color: #fff;
  left: 0;
  .hs-input-wrapper {
    height: 58px;
    margin: 4px 0 8px 22px;
  }
  .hs-add-btn {
    margin-left: 38px;
    border: 1px solid #fff;
    border-radius: 2px;
    padding: 5px 38px;
    cursor: pointer;
    position: absolute;
    top: 35px;
    &:hover {
      opacity: .6;
    }
  }
  p {
    font-size: 12px;
    line-height: 14px;
    margin: 16px 72px;
    opacity: .5;
  }
  input {
    color: #fff;
    background: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    opacity: .6;
    margin-left: 14px;
    padding-left: 2px;
    display: block;
    float: left;
    &[name="name"] {
      width: 118px;
    }
    &[name="url"] {
      width: 188px;
    }
    &:focus {
      opacity: 1;
    }
  }
  span.hs-input-label {
    margin-left: 50px;
    margin-top: 0px;
    height: 28px;
    line-height: 28px;
    display: block;
    float: left;
  }
  .hs-info-icon {
    width: 12px;
    height: 12px;
    float: left;
    margin: 1px 12px 0 -1px;
  }
  .hs-edit-fold {
    float: right;
    margin-top: 5px;
    margin-right: 5px;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .hs-input-warning {
    display: block;
    position: absolute;
    margin: 26px 50px 0 50px;
  }

}

.hotsite-panel.edit-mode .hs-edit-panel {
  display: block;
}


@keyframes shaking {
  0% { transform: rotate(-1deg); }
  50% { transform: rotate(0deg); }
  100% { transform: rotate(1deg); }
}


/*----- Switch Tab Animations -----*/
@keyframes hoverAnimation {
  0% { margin-top: 6px; height: 4px; }
  100% { margin-top: 4px; height: 8px; }
}


/*----- Edit Panel Animations -----*/

.hs-edit-panel.show, .hs-edit-panel.hide{
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.hs-edit-panel.show {
  display: block;
  max-height: 9999px;
  -moz-transform: scaleY(1);
  -ms-transform: scaleY(1);
  -o-transform: scaleY(1);
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  animation: showAnimation 0.5s ease-in-out;
  -moz-animation: showAnimation 0.5s ease-in-out;
  -webkit-animation: showAnimation 0.5s ease-in-out;
  -moz-transition: max-height 1s ease-in-out;
  -o-transition: max-height 1s ease-in-out;
  -webkit-transition: max-height 1s ease-in-out;
  transition: max-height 1s ease-in-out;
}
.hs-edit-panel.hide {
  display: block;
  max-height: 0;
  -moz-transform: scaleY(0);
  -ms-transform: scaleY(0);
  -o-transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  animation: hideAnimation 0.4s ease-out;
  -moz-animation: hideAnimation 0.4s ease-out;
  -webkit-animation: hideAnimation 0.4s ease-out;
  -moz-transition: max-height 0.6s ease-out;
  -o-transition: max-height 0.6s ease-out;
  -webkit-transition: max-height 0.6s ease-out;
  transition: max-height 0.6s ease-out;
}
@keyframes showAnimation {
  0% {
    transform: scaleY(0.1);
  }

  40% {
    transform: scaleY(1.04);
  }

  60% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.04);
  }

  100% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.02);
  }

  100% {
    transform: scaleY(1);
  }
}
@-moz-keyframes showAnimation {
  0% {
    -moz-transform: scaleY(0.1);
  }

  40% {
    -moz-transform: scaleY(1.04);
  }

  60% {
    -moz-transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.04);
  }

  100% {
    -moz-transform: scaleY(0.98);
  }

  80% {
    -moz-transform: scaleY(1.02);
  }

  100% {
    -moz-transform: scaleY(1);
  }
}
@-webkit-keyframes showAnimation {
  0% {
    -webkit-transform: scaleY(0.1);
  }

  40% {
    -webkit-transform: scaleY(1.04);
  }

  60% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.04);
  }

  100% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.02);
  }

  100% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes hideAnimation {
  0% {
    transform: scaleY(1);
  }

  60% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.02);
  }

  100% {
    transform: scaleY(0);
  }
}
@-moz-keyframes hideAnimation {
  0% {
    transform: scaleY(1);
  }

  60% {
    transform: scaleY(0.98);
  }

  80% {
    transform: scaleY(1.02);
  }

  100% {
    transform: scaleY(0);
  }
}
@-webkit-keyframes hideAnimation {
  0% {
    -webkit-transform: scaleY(1);
  }

  60% {
    -webkit-transform: scaleY(0.98);
  }

  80% {
    -webkit-transform: scaleY(1.02);
  }

  100% {
    -webkit-transform: scaleY(0);
  }
}