/*----- Common Styles -----*/
@backgroundColor: #f1f1f1;
body {
  background-color: @backgroundColor;
}
.logo .logo-left {
  background-color: @backgroundColor;
}

.mod {
	margin: 0 0 12px 0;
	float: left;
	background-color: #fff;
}
a {
  color: #6b6b6b;
}
a:hover {
  color: #ff962f;
  text-decoration: none;
}

.main {
	width: 1024px;
	margin: 0 auto;
	position: relative;
	display: table;
}
#J_header {
	height: 62px;
	padding: 12px 0;
	position: relative;
  z-index: 100;
}

.header-links{
	float: right;
}
.header-links .header-link {
	float: left;
	width: 244px;
	height: 62px;
	line-height: 62px;
	margin-left: 10px;
	cursor: pointer;
  &:hover {
    opacity: .8;
  }
}
.header-link a {
  display: block;
  width: 244px;
  height: 62px;
}
.header-link img{
	width: 244px;
  height: 62px;
}





.right-column {
  float: left;
  width: 234px;
  margin-left: 10px;
  div:not( .mail-content ) {
    background-color: #fff;
  }
}
