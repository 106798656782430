.right-push {
  .right-push-item {
    height: 170px;
    padding-top: 6px;
    margin-bottom: 10px;
  }
  a {
    display: block;
    width: 220px;
    height: 164px;
    margin: 0 7px;
    img {
    width: 100%;
    height: 100%;
    }
    &:hover {
      opacity: .8;
    }
  }
}