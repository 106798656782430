#J_tools{
  float: left;
  width: 234px;
  margin-left: 10px;
}
.right-tool, .mail-selector {
  background-color: #fff;
}

.right-tool {
  width: 100%;
  height: 171px;
  position: relative;
  margin-top: 1px;
  padding-top: 10px;
  a {
    display: block;
    width: 117px;
    padding-bottom: 6px;
    float: left;
    position: relative;
  }
  span {
    display: block;
    text-align: center;
  }
  img{
    width: 40px;
    height: 40px;
    margin-left: 38px;
    margin-top: 10px;
  }
}


/*----- Mail -----*/
.mail-selector{
  height: 58px;
  position: relative;
  z-index: 2;
  text-align: center;
  background: #fff;
  cursor: pointer;

  .border-line-box {
    position: absolute;
    // float: left;
    left: 0;
    width: 232px;
    height: 56px;
    background: transparent;
    border: 1px solid transparent;
    z-index: 1;
    &.paint-border {
      display: block;
      border: 1px solid #cfcfcf;
      border-bottom: none;
    }
  }
}
.mail-content {
  float: left;
  line-height: 58px;
  .mail-item {
    width: 183px;
    height: 57px;
    margin: 1px 0 0 1px;
  }
}

.mail-content, .mail-arrow {
  position: relative;
  z-index: 2;
}
.mail-arrow{
  float: right;
  width: 50px;
  height: 58px;
}
.mail-arrow-icon {
  float: right;
  width: 10px;
  height: 10px;
  margin: 24px 30px;
  transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transition: transform .3s;
  &.reversed {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transition: transform .3s;
  }
}

.mail-text {
  display: block;
  cursor: pointer;
  padding-left: 19px;
  span {
    margin-left: 14px;
  }
  &:hover {
    text-decoration: none;
    color: #555555;
  }
}



.mail-list {
  height: 0;
  width: 232px;
  position: absolute;
  overflow: hidden;
  top: 57px;
  left: 0;
  z-index: 90;
  border: 1px solid #cfcfcf;
  border-top: none;

  .mail-item:hover {
    background-color:#f1f1f1;
  }
}
.mail-item {
  display: block;
  width: 100%;
  height: 58px;
  line-height: 58px;
  background-color: #fff;

  span {
    float: left;
  }
  img{
    width: 24px;
    height: 24px;
    vertical-align: bottom;
    float: left;
    margin: 17px 14px 0 19px;
  }

}



.mail-edit-icon {
  width: 24px;
  height: 24px;
  margin-top: 17px;
  float: left;
  cursor: pointer;
}
.mail-edit.to-left{
  position: absolute;
  left: 16px;
}



