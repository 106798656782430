.bg-wrapper {
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: 11;
}
.bg-left {
  a {
    float: right;
  }
}

.bg-right {
  a {
    float: left;
  }
  .even-row {
    margin-left: -104px;
  }
}

.bg-row {
  height: 138px;
  overflow: hidden;
  a {
    display: block;
    width: 138px;
    height: 138px;
    background-repeat: no-repeat;
    background-position: center;
  }
  span {
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(244,244,245,0), #f4f4f5 66%);
  }
}

#close_background {
  position: absolute;
  z-index: 12;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 8px;
}


