@charset "UTF-8";
html,
body,
div,
form,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

ol, ul {
  list-style-type: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

input,
select,
textarea {
  outline: none;
}

abbr {
  border-bottom: none;
}

.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: "";
  display: table;
  clear: both;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.lfloat {
  float: left;
}

.rfloat {
  float: right;
}

.fwb {
  font-weight: bold;
}

.pas {
  padding: 5px;
}

.pam {
  padding: 10px;
}

.pal {
  padding: 20px;
}

.pts {
  padding-top: 5px;
}

.ptm {
  padding-top: 10px;
}

.ptl {
  padding-top: 20px;
}

.prs {
  padding-right: 5px;
}

.prm {
  padding-right: 10px;
}

.prl {
  padding-right: 20px;
}

.pbs {
  padding-bottom: 5px;
}

.pbm {
  padding-bottom: 10px;
}

.pbl {
  padding-bottom: 20px;
}

.pls {
  padding-left: 5px;
}

.plm {
  padding-left: 10px;
}

.pll {
  padding-left: 20px;
}

.phs {
  padding-left: 5px;
  padding-right: 5px;
}

.phm {
  padding-left: 10px;
  padding-right: 10px;
}

.phl {
  padding-left: 20px;
  padding-right: 20px;
}

.pvs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pvm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pvl {
  padding-top: 20px;
  padding-bottom: 20px;
}

.mas {
  margin: 5px;
}

.mam {
  margin: 10px;
}

.mal {
  margin: 20px;
}

.mts {
  margin-top: 5px;
}

.mtm {
  margin-top: 10px;
}

.mtl {
  margin-top: 20px;
}

.mrs {
  margin-right: 5px;
}

.mrm {
  margin-right: 10px;
}

.mrl {
  margin-right: 20px;
}

.mbs {
  margin-bottom: 5px;
}

.mbm {
  margin-bottom: 10px;
}

.mbl {
  margin-bottom: 20px;
}

.mls {
  margin-left: 5px;
}

.mlm {
  margin-left: 10px;
}

.mll {
  margin-left: 20px;
}

.mhs {
  margin-left: 5px;
  margin-right: 5px;
}

.mhm {
  margin-left: 10px;
  margin-right: 10px;
}

.mhl {
  margin-left: 20px;
  margin-right: 20px;
}

.mvs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mvm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mvl {
  margin-top: 20px;
  margin-bottom: 20px;
}

.text_align_center {
  text-align: center !important;
}

.text_align_ltr {
  text-align: left !important;
}

.text_align_rtl {
  text-align: right !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.direction_ltr {
  direction: ltr;
}

.direction_rtl {
  direction: rtl;
}

.text_align_ltr {
  text-align: left;
}

.text_align_rtl {
  text-align: right;
}

.inline-block {
  display: inline-block;
  zoom: 1;
}

.hidden-element{
  display: none!important;
}

body,
button,
input,
label,
select,
td,
textarea {
  font: 14px/18px arial, sans-serif, tahoma, verdana, "Simsun";
}

body {
  color: #555555;
  background-color: #f5f5f5;
}

a {
  color: #555555;
  text-decoration: none;
}

a:hover {
  color: #ff962f;
  text-decoration: underline;
}

.uc-section,
.mod,
.bd {
  zoom: 1;
}
.uc-section:after,
.mod:after,
.bd:after {
  content: "";
  display: table;
  clear: both;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.arrow {
  border: 5px solid transparent;
  border-top-color: #aaa;
}

/* sprite */
.sprite-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 0 14px 0 18px;
  vertical-align: top;
  *vertical-align: middle;
}
