#J_coolSites{
  display: table;
  width: 780px;
  margin-bottom: 15px;
  float: left;
}
.coolsite{
  width: 748px;
  min-height: 176px;
  margin-bottom: 10px;
  padding: 0 16px;
  position: relative;
  a.emphasis {
    font-weight: bold;
  }
}
.coolsite-download {
  .coolsite-title {
    color: #93e386;
  }
}
.coolsite-software {
  .coolsite-title {
    color: #7c7c7c;
  }
}

.coolsite-music {
  .coolsite-title{
    color: #3e99ff;
  }
}

.coolsite-shopping {
  .coolsite-title{
    color: #8a8df0;
  }
}

.coolsite-news {
  .coolsite-title{
    color: #ff6363;
  }
}

.coolsite-life .coolsite-title{
  color: #ebc83b;
}

.coolsite-finance .coolsite-title{
  color: #7add68;
}

.coolsite-sports .coolsite-title{
  color: #ff6363;
}

.coolsite-title{
  height: 33px;
  line-height: 33px;
  margin-top: 7px;
  font-size: 18px;
  border-bottom: 1px solid #f1f1f1;
  padding-left: 10px;
}

.coolsite-content{
  overflow: hidden;
  width: 700px;
  margin: 21px 0 30px 12px;
  display: block;
}
.coolsite-content li{
  width: 123px;
  height: 32px;
  line-height: 32px;
  float: left;
}
.coolsite-content a{
  display: block;
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.coolsite-logo {
  position: absolute;
  right: 0;
  bottom: 0;
}