.footer {
  clear: both;
  padding: 30px 0;
  border-top: 1px solid #e8e8e8;
  font-size: 12px;
  text-align: center;
  background-color: #e3e3e3;
  min-width: 1024px;
}
.footer p {
  line-height: 24px;
}

.footer .line {
  margin: 0 5px;
  vertical-align: middle;
  color: #666666;
}
.footer .copyright {
  color: #999999;
}

