/***** Common styles *****/
.mid-banner {
  width: 100%;
  height: 0;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  a {
    display:block;
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
  }
  span {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
  }
}
