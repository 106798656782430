.hs-delete, .hs-edit-btn, .hs-info-icon, .hs-edit-fold,
.mail-arrow-icon,
.search-arrow-icon,
.logo-inner,.logo-left-close-btn,
.mid-banner-close-btn,
#close_background
{
  background: url(../img/new_sprite.png);
}

/*----- 名站自定义删除及编辑按钮 -----*/
.hs-delete {
  background-position: -1px -74px;
  &:hover {
    background-position: -24px -74px;
  }
}
.hs-edit-btn {
  background-position: -51px -60px;
  &:hover {
    background-position: -75px -60px;
  }
}

/*----- 名站编辑面板信息提示 -----*/
.hs-info-icon {
  background-position: -52px -84px;
}

/*----- 名站编辑面板收起按钮 -----*/
.hs-edit-fold {
  background-position: -33px -38px;
  &:hover {
    background-position: -55px -38px;
  }
  &:active {
    background-position: -77px -38px;
  }
}

/*----- 邮箱、搜索下拉框箭头 -----*/
.mail-arrow-icon, .search-arrow-icon {
  background-position: -34px -63px;
}

.mail-arrow:hover .mail-arrow-icon,
.search-selector:hover .search-arrow-icon {
  background-position: -24px -63px;
}


/*----- 网页左上角logo -----*/
.logo-inner {
  background-position: 1px -1px;
}

/*----- 左侧悬挂广告关闭按钮 -----*/
.logo-left-close-btn {
  background-position: -124px -60px;
  &:hover {
    background-position: -100px -60px;
  }
}

/*----- 中屏广告banner关闭按钮 -----*/
.mid-banner-close-btn {
  background-position: -128px -64px;
  &:hover {
    background-position: -104px -64px;
  }
}

/*----- 背景Logo关闭按钮 -----*/
#close_background {
  background-position: -122px -39px;
  &:hover {
    background-position: -100px -39px;
  }
}


